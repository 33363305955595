import React, { Component } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToastNotifications from '../../../components/toasty-notifications/toasty-notifications';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import MDSpinner from 'react-md-spinner';

class VehicleForm extends Component {
  handleVehicleSubmit(vehicleData) {
    const route = '/manage_bike';
    const method = 'post';
    const params = vehicleData;
    const verb = vehicleData.id === -1 ? 'add' : 'edit';
    const onSuccess = () => {
      ToastNotifications.successToast(`Vehicle ${verb}ed successfully`, 5000);
    };
    const onFailure = () => {
      ToastNotifications.errorToast('', `Sorry, something went wrong while ${verb}ing bike`, 5000);
    };

    this.props.authService.secureAjax({
      route,
      method,
      params,
      onSuccess,
      onFailure,
    });
  }

  presentFloat(float) {
    return Math.round(float).toLocaleString();
  }

  render() {
    const { bike } = this.props;

    // Return a loading indicator if bike data is not yet available
    if (!bike || !bike.summary) {
      return <MDSpinner />;
    }

    const { id, make, model, vrn, imei, summary, addNewBike } = bike;
    const odometerMiles = this.presentFloat(summary.odometerMiles);

    return (
      <Formik
        initialValues={{ id, make, model, vrn, imei, odometerMiles }}
        onSubmit={(values) => {
          this.handleVehicleSubmit(values);
        }}
        enableReinitialize
        render={({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className="default-form">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Registration Number"
                className="floating-label-custom"
              >
                <Form.Control
                  type="text"
                  name="vrn"
                  value={values.vrn}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Device IMEI"
                className="floating-label-custom"
              >
                <Form.Control
                  readOnly={!addNewBike}
                  type="text"
                  name="imei"
                  value={values.imei}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Manufacturer"
                className="floating-label-custom"
              >
                <Form.Control
                  type="text"
                  name="make"
                  value={values.make}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Model"
                className="floating-label-custom"
              >
                <Form.Control
                  type="text"
                  name="model"
                  value={values.model}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Odometer"
                className="floating-label-custom"
              >
                <Form.Control
                  type="text"
                  name="odometerMiles"
                  value={values.odometerMiles}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Button type="submit" className="primary-button" style={{ marginTop: 15 }}>
              Save
            </Button>
          </Form>
        )}
      />
    );
  }
}

export default VehicleForm;
