import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BackButton from '../../components/back_button';
import axios from 'axios/index';
import { LoopingRhombusesSpinner } from 'react-epic-spinners';
import * as Sentry from '@sentry/react';
import './reset_password.css';
import { SpinningButton } from '../signup/submit-button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { BackButtonHeader } from '../../components/back_button_header';
import { ResetConfirmation } from './reset_confirmation';
import { Link } from 'react-router-dom';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailValid: false,
      loading: false,
      codeSent: false,
      pin: '',
    };
  }

  handleSubmit(e) {
    console.log(e);
    e.preventDefault();
    this.setState({ loading: true });

    const route = process.env.REACT_APP_API_URL + '/forgot_password';
    return axios
      .post(route, {
        email: this.state.email,
        withCredentials: true,
      })
      .then((response) => {
        this.props.handleStateChange({
          name: 'formMessage',
          state: 'We have sent a password recover instructions to your email',
        });
        this.setState({ loading: false, codeSent: true });
      })
      .catch((error) => {
        if (error.response.data && error.response.data.msg) {
          this.props.handleStateChange({
            name: 'formMessage',
            state: error.response.data.msg,
          });
        } else {
          this.props.handleStateChange({
            name: 'formMessage',
            state: 'Something went wrong',
          });
        }
        console.log(error);
        this.setState({ loading: false });
        Sentry.captureException(new Error(error));
      });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === 'email') {
        this.validateEmailField(value);
      }
    });
  }

  handleOnBlur() {
    this.props.handleStateChange({
      name: 'formMessage',
      state: this.state.emailValid ? '' : 'Your email address is invalid.',
    });
  }

  validateEmailField(email) {
    let emailValid = this.state.emailValid;
    emailValid = !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    this.setState({
      emailValid: emailValid,
    });
  }

  render() {
    return (
      <div className="main-container">
        <BackButtonHeader
          text="Reset password"
          backDestination="forgotPasswordForm"
          onPress={() =>
            this.props.handleStateChange({
              name: 'forgotPasswordForm',
              state: false,
            })
          }
        />
        {!this.state.codeSent && (
          <Form onSubmit={(e) => this.handleSubmit(e)} className="regular-form">
            {this.props.formMessage && <div style={{ color: 'red' }}>{this.props.formMessage}</div>}
            <Form.Text className="regular-text standart-bottom-padding">
              Enter the email associated with your account and we'll send an email with instructions
              to reset your password.
            </Form.Text>
            <Form.Group controlId="formBasicEmail" className="standart-bottom-padding">
              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="floating-label-custom"
              >
                <Form.Control
                  type="email"
                  name="email"
                  disabled={this.state.codeSent}
                  value={this.state.email}
                  className="form-input"
                  onChange={this.handleUserInput.bind(this)}
                  placeholder=" "
                  onBlur={(e) => this.handleOnBlur()}
                />
              </FloatingLabel>
            </Form.Group>
            {this.state.codeSent && (
              <Form.Group className="standart-bottom-padding">
                <FloatingLabel
                  controlId="floatingInput"
                  label="PIN Code"
                  className="floating-label-custom"
                >
                  <Form.Control
                    type="number"
                    name="pin"
                    value={this.state.pin}
                    className="form-input"
                    onChange={this.handleUserInput.bind(this)}
                    placeholder=" "
                    onBlur={(e) => this.handleOnBlur()}
                  />
                </FloatingLabel>
              </Form.Group>
            )}
            <div className="forgot-password-buttons-container" style={{ marginBottom: 20 }}>
              <SpinningButton
                action={(e) => this.handleSubmit(e)}
                loading={this.state.loading}
                buttonVariant={'primary-button'}
                title={this.state.codeSent ? 'Confirm PIN' : 'Confirm Email'}
                visible={true}
                disabled={!this.state.emailValid || this.state.loading || this.state.codeSent}
              />
            </div>
            <Link className="clickable-link" onClick={() => this.setState({ codeSent: true })}>
              Already have a code?
            </Link>
          </Form>
        )}
        {this.state.codeSent && <ResetConfirmation emailAddress={this.state.email} />}
      </div>
    );
  }
}

export default ForgotPassword;
