import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ToastNotifications from '../../../components/toasty-notifications/toasty-notifications';

export const DeleteConfirmation = ({ authService, email, password, setShow }) => {
  const [showModal, setShowModal] = useState(true);

  const handleHide = () => setShow(false);

  const handleSubmit = () => {
    authService.secureAjax({
      params: { currentPassword: password, email },
      route: '/delete_customer_account',
      method: 'post',
      onSuccess() {
        setShowModal(false);
      },
      onFailure(error) {
        let errorMsg = 'Something went wrong';
        console.log(error.response.status);
        if (error.response.status === 404) {
          errorMsg = 'Email address is incorrect';
        }
        if (error.response.status === 401) {
          errorMsg = 'Password is incorrect';
        }
        setShow(false);
        ToastNotifications.errorToast('', errorMsg, 3000);
      },
    });
  };

  return (
    <>
      <Modal show={showModal} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Delete your Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>Goodbye from Motohalo 👋</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleHide} variant="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="primary">
            Goodbye
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={!showModal} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Goodbye</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thank you for spending time with us as a MotoHalo customer! We wish you all the best for
          the future 🤝
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={authService.signout} variant="primary">
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteConfirmation;
