import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMotorcycle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
export const HeaderAndDevicesList = ({ addedDevices }) => {
  return (
    <>
      {addedDevices.length !== 0 ? (
        <>
          <h5>Your New Devices</h5>
          <hr className="separator" />
          <div className="info-container">
            {addedDevices.map((d, index) => (
              <div key={index + 1} className="info-row">
                <div className="fa-info-icon">
                  <FontAwesomeIcon icon={faMotorcycle} />
                </div>
                <div className="info-text">{d}</div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </>
  );
};
