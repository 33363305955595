import { notify } from 'react-notify-toast';
import React from 'react';
import './toasty-notifications.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesSquare } from '@fortawesome/pro-light-svg-icons/faTimesSquare';
import { resendActivationToken } from '../../views/signup/sign-up-utils';

let myColor = { background: '#F9DDE0', text: '#C74E56' };
const ToastNotifications = {
  pageReload() {
    window.location.reload();
  },

  defaultErrorToast(error, timeOut) {
    if (error.message !== 'Network Error') {
      console.error(error);
      notify.show(
        <div
          style={{ color: myColor.text, background: myColor.background, borderColor: myColor.text }}
          className="toast-notify-body"
        >
          <div className="toast-notify-text">
            Ooops! Something went wrong. <br />
            We were notified of this Error: &nbsp;
            <a style={{ textDecoration: 'underline' }} onClick={this.pageReload}>
              Click to Reload
            </a>
          </div>
          <div className="toast-notify-buttons-wrapper">
            <div className="toast-notify-close-button">
              <FontAwesomeIcon onClick={notify.hide} icon={faTimesSquare} />
            </div>
          </div>
        </div>,
        'error',
        timeOut,
        myColor,
      );
    }
  },

  errorToast(error, message, timeOut) {
    if (error.message !== 'Network Error') {
      notify.show(
        <div
          style={{ color: myColor.text, background: myColor.background, borderColor: myColor.text }}
          className="toast-notify-body"
        >
          <div className="toast-notify-text">{message}</div>
          <div className="toast-notify-buttons-wrapper">
            <div className="toast-notify-close-button">
              <FontAwesomeIcon onClick={notify.hide} icon={faTimesSquare} />
            </div>
          </div>
        </div>,
        'error',
        timeOut,
        myColor,
      );
    }
  },

  unverifiedEmailToast(error, timeOut, email) {
    const link = `/signup?needsActivating=true&email=${email}`;
    const linkMessage = 'Click here to resend it.';
    if (error.message !== 'Network Error') {
      console.error(error);
      notify.show(
        <div
          style={{ color: myColor.text, background: myColor.background, borderColor: myColor.text }}
          className="toast-notify-body"
        >
          <div className="toast-notify-text">
            {error}
            <a
              style={{ textDecoration: 'underline' }}
              onClick={() => resendActivationToken(email, true, link)}
            >
              {linkMessage}
            </a>
          </div>
          <div className="toast-notify-buttons-wrapper">
            <div className="toast-notify-close-button">
              <FontAwesomeIcon onClick={notify.hide} icon={faTimesSquare} />
            </div>
          </div>
        </div>,
        'error',
        timeOut,
        myColor,
      );
    }
  },

  successToast(message, timeOut) {
    notify.show(
      <div
        style={{ color: '#68972B', background: '#F1F5EB', borderColor: '#68972B' }}
        className="toast-notify-body"
      >
        <div className="toast-notify-text">{message}</div>
        <div className="toast-notify-buttons-wrapper">
          <div className="toast-notify-close-button">
            <FontAwesomeIcon onClick={notify.hide} icon={faTimesSquare} />
          </div>
        </div>
      </div>,
      'success',
      timeOut,
    );
  },
};

export { ToastNotifications as default };
