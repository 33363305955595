import React from 'react';
import Button from 'react-bootstrap/Button';
import './download_app.css';
import { isAndroid, isMobile } from 'react-device-detect';

const DownloadApp = () => {
  const getIOSApp = () => {
    window.location = 'https://apps.apple.com/gb/app/moto-halo/id1505515077';
  };

  const getAndroidApp = () => {
    window.location = 'https://play.google.com/store/apps/details?id=com.bikeeyes';
  };

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 20 }}>
      {!isMobile ? (
        <div>
          <Button
            style={{ marginBottom: 20 }}
            className="primary-button"
            type="submit"
            onClick={() => getAndroidApp()}
          >
            📱 Google Play
          </Button>
          <Button className="primary-button" type="submit" onClick={() => getIOSApp()}>
            📱 App Store
          </Button>
        </div>
      ) : isAndroid ? (
        <Button className="primary-button" type="submit" onClick={() => getAndroidApp()}>
          📱 Google Play
        </Button>
      ) : (
        <Button className="primary-button" type="submit" onClick={() => getIOSApp()}>
          📱 App Store
        </Button>
      )}
    </div>
  );
};

export default DownloadApp;
