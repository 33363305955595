import React, { Component } from 'react';
import './settings.css';
import { Switch, Route, NavLink, withRouter } from 'react-router-dom';
import CustomerDetails from './account_settings/customer_details';
import EditVehicles from './bike_settings/edit_vehicles';
import LogOutButton from '../login/logout_button';
import ManageBilling from './manage_billing.js';
import DownloadApp from './download_app/download_app';
import DeleteAccount from './delete_account/delete_account';
import EmergencyContact from './emergency_contact/emergency_contact';
import { faSignOutAlt, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Notification from 'react-notify-toast';
import { BackButtonHeader } from '../../components/back_button_header';
import { Logo } from '../../components/logo';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePageTitle: 'Profile',
    };
    this.changeTitleName = this.changeTitleName.bind(this);
    this.setDefaultTitleName = this.setDefaultTitleName.bind(this);
    this.specialHeaderTitle = this.specialHeaderTitle.bind(this);
  }

  changeTitleName(title) {
    this.setState({ profilePageTitle: title });
  }

  setDefaultTitleName() {
    this.setState({ profilePageTitle: 'Profile' });
  }

  specialHeaderTitle(value) {
    const title = 'ARE YOU SURE?';
    if (value === 'Delete Account') {
      return `${title} \uFE0F\u231b `;
    } else {
      return value;
    }
  }

  buttonControls() {
    return [
      {
        name: 'Get Mobile App',
        src: '/icons/download.png',
        alt: 'download icon',
        route: 'download-app',
        render: () => <DownloadApp />,
      },
      {
        name: this.state.profilePageTitle,
        src: '/icons/user.png',
        alt: 'user icon',
        route: 'customer-details',
        render: () => (
          <CustomerDetails
            authService={this.props.authService}
            changeTitleName={this.changeTitleName}
          />
        ),
      },
      {
        name: 'Emergency Contact',
        src: '/icons/emergencyContact.png',
        alt: 'emergency contact icon',
        route: 'emergency-contact',
        render: () => <EmergencyContact authService={this.props.authService} />,
      },
      {
        name: 'Vehicle Settings',
        src: '/icons/requestService.png',
        alt: 'vehicle icon',
        route: 'vehicle-settings',
        render: () => (
          <EditVehicles bikes={this.props.bikes} authService={this.props.authService} />
        ),
      },
      {
        name: 'Billing',
        src: '/icons/billing.png',
        alt: 'billing icon',
        route: 'billing',
        render: () => <ManageBilling authService={this.props.authService} />,
      },
      {
        name: 'Delete Account',
        src: '/icons/delete.png',
        alt: 'delete icon',
        route: 'delete-account',
        render: () => <DeleteAccount authService={this.props.authService} />,
      },
    ];
  }

  renderButtonControls() {
    const { url } = this.props.match;

    return (
      <>
        {this.buttonControls().map((bc, index) => (
          <NavLink
            exact
            className="settings_page_buttons"
            activeClassName="settings_page_buttons"
            key={index}
            to={`${url}/${bc.route}`}
            onClick={() => {
              this.changeTitleName(bc.name);
            }}
            aria-label={`Navigate to ${bc.name} settings`}
          >
            <div className="settings-option-container">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img src={bc.src} alt={bc.alt} className="settings_button_icon" />
                <p className="regular-text reset-margin" style={{ fontSize: 25 }}>
                  {bc.name}
                </p>
              </div>
              <FontAwesomeIcon className="chevron-icon" icon={faChevronRight} />
            </div>
          </NavLink>
        ))}
        <LogOutButton authService={this.props.authService} icon={faSignOutAlt} />
      </>
    );
  }

  render() {
    const { location, match } = this.props;
    const { path } = match;

    // Determine if we are on the main settings page or a sub-page
    const isMainSettingsPage = location.pathname === path;

    // Choose animation class based on the current route
    const animationClass = isMainSettingsPage ? 'slide-left' : 'slide-right';

    return (
      <div className="settings-page">
        <TransitionGroup className="transition-group">
          <CSSTransition key={location.key} classNames={animationClass} timeout={300}>
            <div className="route-section">
              <Switch location={location}>
                <Route exact path={path}>
                  <div className="screen">
                    <div className="main-container">
                      <Logo paddingBottom={15} />
                      {this.renderButtonControls()}
                    </div>
                  </div>
                </Route>
                {this.buttonControls().map((bc, index) => (
                  <Route
                    key={index}
                    path={`${path}/${bc.route}`}
                    render={(routeProps) => (
                      <div className="screen">
                        <div className="main-container no-gap">
                          <Notification options={{ position: 'relative' }} />
                          <BackButtonHeader
                            text={this.specialHeaderTitle(bc.name)}
                            onPress={() => {
                              routeProps.history.goBack();
                              this.setDefaultTitleName();
                            }}
                          />
                          <div className="render-container">{bc.render()}</div>
                        </div>
                      </div>
                    )}
                  />
                ))}
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

export default withRouter(Settings);
