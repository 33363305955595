import React, { useState } from 'react';
import './account_settings.css';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToastNotifications from '../../../components/toasty-notifications/toasty-notifications';
import * as Sentry from '@sentry/react';
import { customerValidationSchema } from '../../../services/validation_schema';
import ChangePassword from './change_password';
import ChangeEmail from './change_email/change_email';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const CustomerInfo = ({
  customer,
  authService,
  onSuccess,
  activateForm,
  getCustomer,
  changeTitleName,
}) => {
  const [settingsPage, setSettingsPage] = useState('Profile');
  const { accountHolder } = customer;
  const changeSettingsPage = (value) => {
    setSettingsPage(value);
    changeTitleName(value);
  };

  return (
    <div style={{ marginTop: 20, width: '100%' }}>
      {settingsPage === 'Change E-Mail Address' && (
        <ChangeEmail authService={authService} customer={customer} getCustomer={getCustomer} />
      )}
      {settingsPage === 'Change Password' && <ChangePassword />}
      {settingsPage === 'Profile' && (
        <Formik
          initialValues={{
            ...customer,
            name: accountHolder && accountHolder.name,
            phoneNumber: (accountHolder && accountHolder.phoneNumber) || '',
          }}
          style={{ backgroundColor: 'pink' }}
          enableReinitialize
          validationSchema={customerValidationSchema}
          onSubmit={(values) => {
            values.updateEmergencyDetails = true;
            console.log(JSON.stringify(values));
            updateCustomerDetails(values, authService, onSuccess);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            errors,
            touched,
            isValid,
            initialValues,
          }) => {
            return (
              <Form
                onSubmit={handleSubmit}
                style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 20 }}
              >
                <Form.Group controlId="customerForm.name">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Full Name"
                    className="floating-label-custom"
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur('name')}
                      className="form-input"
                      onChange={handleChange}
                      placeholder=" "
                    />
                  </FloatingLabel>
                </Form.Group>

                <div className="change-profile_row">
                  <Form.Group controlId="customerForm.email" style={{ display: 'flex', flex: 1 }}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email"
                      className="floating-label-custom"
                    >
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        className="form-input"
                        readOnly
                        placeholder=" "
                        disabled={true}
                      />
                      <DisplayErrors error={touched.email && errors.email} field="email" />
                    </FloatingLabel>
                  </Form.Group>
                  <Button
                    className="edit-button"
                    onClick={() => changeSettingsPage('Change E-Mail Address')}
                    type="submit"
                    disabled={!values.email}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                    Edit
                  </Button>
                </div>

                <div className="change-profile_row">
                  <Form.Group
                    controlId="customerForm.password"
                    style={{ display: 'flex', flex: 1 }}
                  >
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Password"
                      className="floating-label-custom"
                    >
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        className="form-input"
                        onChange={handleChange}
                        placeholder=" "
                        disabled={true}
                      />
                      <DisplayErrors error={touched.password && errors.password} field="password" />
                    </FloatingLabel>
                  </Form.Group>
                  <Button
                    className="edit-button"
                    onClick={() => changeSettingsPage('Change Password')}
                    type="submit"
                  >
                    <FontAwesomeIcon icon={faPencil} />
                    Edit
                  </Button>
                </div>
                <Form.Group controlId="customerForm.phoneNumber">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Phone Number"
                    className="floating-label-custom"
                  >
                    <Form.Control
                      type="text"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      className="form-input"
                      onChange={handleChange}
                      onBlur={handleBlur('phoneNumber')}
                      placeholder=" "
                    />
                  </FloatingLabel>
                  <p className="small-text" style={{ marginTop: 5 }}>
                    SMS messages for your alerts will be sent to this number, so please keep it up
                    to date.
                  </p>
                  <DisplayErrors error={!values.phoneNumber || errors.phoneNumber} />
                </Form.Group>

                <Button
                  type="submit"
                  disabled={
                    values.name === initialValues.name ||
                    (touched.phoneNumber && values.phoneNumber.length && !isValid)
                  }
                  className="primary-button"
                >
                  {activateForm ? 'Finish Account Setup' : 'Update Details'}
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default CustomerInfo;

function updateCustomerDetails(customer, authService, onSuccess) {
  authService.secureAjax({
    route: '/update_customer',
    method: 'post',
    params: customer,
    onSuccess,
    onFailure(error) {
      ToastNotifications.defaultErrorToast(error, -1);
      Sentry.captureException(new Error(error));
    },
  });
}

function DisplayErrors({ error, field }) {
  return error ? (
    <div style={{ color: '#fa2a43', paddingBottom: '3px' }}>
      {error}
      <br />{' '}
    </div>
  ) : null;
}

// const REQUIRE_PRESENCE = "Must be present";
// const PHONE_REGEX = /^(([+]\d{2}[ ]?)|0)\d{10}$/;
// const EMAIL_FORMAT_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

// function validate(values) {
//   const errors = {};
//
//   function ensurePresence(field) {
//     if (!values[field]){
//       errors[field] = REQUIRE_PRESENCE;
//     }
//   }
//
//   function validateFormat(field, regex, message, trimWhiteSpaces) {
//     if (trimWhiteSpaces) {
//       values[field] = values[field].replace(/\s+/g, '');
//     }
//     if (!regex.test(values[field]) && !errors[field]) { errors[field] = message; }
//   }
//
//   ['name', 'email', 'phoneNumber', 'emergencyName', 'emergencyPhoneNumber','emergencyContactRelationship' ].forEach(ensurePresence);
//
//   validateFormat('email',EMAIL_FORMAT_REGEX, 'Invalid email address');
//
//   validateFormat('phoneNumber', PHONE_REGEX, 'Please enter a valid number', true);
//
//   for (let prop in errors){
//     if (errors.hasOwnProperty(prop)){
//       // console.log(`${prop} has error: ${errors[prop]}`)
//     }
//   }
//
//   return errors;
// };
