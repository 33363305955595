import React, { useEffect } from 'react';
import { useState } from 'react';
import { BackButtonHeader } from '../../components/back_button_header';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { SpinningButton } from '../signup/submit-button';
import { resetPasswordVerification } from '../../services/reset_password_verification';
import ToastNotifications from '../../components/toasty-notifications/toasty-notifications';
//
// export const responseHandler = {
//   success: (msg, navigation) => {
//     Alert.alert(
//       'Confirmation',
//       msg,
//       [
//         {
//           text: 'OK',
//           onPress: () => {
//             navigation.navigate('SignIn');
//           },
//         },
//       ],
//       { cancelable: false },
//     );
//   },
//   error: (notify, errorMsg) => notify(errorMsg, 'error'),
// };

export const ResetConfirmation = ({ emailAddress }) => {
  const [code, setCode] = useState(null);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(emailAddress);
  const [errorMessage, setErrorMessage] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState('New Password');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(password.length);
    if (password && password.length < 7) {
      setPasswordWarning('Password shoud be at least 8 characters long');
    } else {
      setPasswordWarning('New Password');
    }
    switch (name) {
      case 'code':
        setCode(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        setEmail(value);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('');
    }
  }, [code, password, email]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    resetPasswordVerification({ email, code, password })
      .then((response) => {
        setLoading(false);
        ToastNotifications.successToast('Passwors succesfully updated', 3000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data && error.response.data.msg) {
          console.log(error.response.data.msg);
          ToastNotifications.errorToast(error, error.response.data.msg, 4000);
        } else {
          ToastNotifications.errorToast(error, 'Something went wrong', 4000);
        }
      });
  };

  return (
    <div>
      <p className="regular-text" style={{ marginBottom: 20 }}>
        Please use the code that we send by email. It may take a few minutes to arrive. (Remember to
        check your spam folder!)
      </p>
      <Form className="regular-form" onSubmit={(e) => handleResetPassword(e)}>
        {errorMessage && (
          <p className="regular-text" style={{ marginBottom: 5, color: 'red' }}>
            {errorMessage}
          </p>
        )}
        <Form.Group controlId="formBasicEmail" className="standart-bottom-padding">
          <FloatingLabel
            controlId="floatingInput"
            label="Email address"
            className="floating-label-custom"
          >
            <Form.Control
              type="email"
              name="email"
              disabled={!!emailAddress}
              value={email}
              onChange={handleInputChange}
              className="form-input"
              placeholder=" "
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="standart-bottom-padding">
          <FloatingLabel
            controlId="floatingInput"
            label="PIN Code"
            className="floating-label-custom"
          >
            <Form.Control
              type="number"
              name="code"
              value={code}
              className="form-input"
              onChange={handleInputChange}
              placeholder=" "
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="standart-bottom-padding" style={{ marginTop: 0 }}>
          {/*<p*/}
          {/*  className="regular-text"*/}
          {/*  style={{*/}
          {/*    color: 'red',*/}
          {/*    marginBottom: 5,*/}
          {/*    opacity: password && password.length < 8 ? 1 : 0,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Password shoud be at least 8 characters long*/}
          {/*</p>*/}
          <FloatingLabel
            controlId="floatingInput"
            label={passwordWarning}
            className="floating-label-custom"
          >
            <Form.Control
              type="password"
              name="password"
              value={password}
              className="form-input"
              onChange={handleInputChange}
              placeholder=" "
              onBlur={() => {
                if (password && password.length < 8) {
                  setPasswordWarning('Password shoud be at least 8 characters long');
                } else {
                  setPasswordWarning('New Password');
                }
              }}
            />
          </FloatingLabel>
        </Form.Group>
        <div className="forgot-password-buttons-container">
          <SpinningButton
            action={(e) => handleResetPassword(e)}
            loading={loading}
            buttonVariant={'primary-button'}
            title="Submit"
            visible={true}
            disabled={!email || !code || password.length < 8}
          />
        </div>
      </Form>
    </div>
  );
};
