import React, { Component } from 'react';

import VehicleForm from './vehicle_form';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

class EditVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBikeIndex: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ selectedBikeIndex: event.target.value });
  }

  render() {
    const bikes = this.props.bikes;
    const selectedBike = bikes[this.state.selectedBikeIndex];
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Form className="default-form" style={{ marginTop: 35 }}>
          <Form.Group controlId="customerForm.name" style={{ marginBottom: 20 }}>
            <FloatingLabel
              controlId="floatingSelect"
              label="Select a bike to edit"
              className="floating-label-custom"
            >
              <Form.Select
                name="name"
                className="form-input select"
                onChange={this.handleChange}
                value={this.state.selectedBikeIndex}
                aria-label="Select a bike"
              >
                <option value="">Please select...</option>
                {bikes.map((bike, index) => (
                  <option value={index} key={bike.id}>
                    {bike.vrn}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Form>
        <VehicleForm bike={selectedBike} authService={this.props.authService} />
      </div>
    );
  }
}

export default EditVehicles;
