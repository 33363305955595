import axios from 'axios';
import * as Sentry from '@sentry/react';
class AuthService {
  constructor(app) {
    this.app = app;
    this.setAuthenticated = this.setAuthenticated.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.secureAjax = this.secureAjax.bind(this);
    this.signout = this.signout.bind(this);
  }

  setAuthenticated(authenticated) {
    this.app.setState({ isAuthenticated: !!authenticated });
  }

  isAuthenticated() {
    return this.app.state.isAuthenticated;
  }

  authenticate(email, password, rememberMe) {
    const authService = this;
    const route = process.env.REACT_APP_API_URL + '/sign_in';
    return axios
      .post(
        route,
        {
          email,
          password,
          rememberMe,
        },
        { withCredentials: true },
      )
      .then(function (response) {
        if (rememberMe) {
          localStorage.setItem('isAuthenticated', 'true');
        }
        authService.setAuthenticated(true);
        return {
          isAuthenticated: true,
          data: response.data,
        };
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(new Error(error));
        return {
          error: error,
          isAuthenticated: false,
          data: {},
        };
      });
  }

  signout() {
    this.setAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    const route = process.env.REACT_APP_API_URL + '/sign_out';
    return axios.get(route, { withCredentials: true }).then((window.location = '/'));
  }

  sendToBilling() {
    if (window.location.href !== '/manage_billing') {
      window.location = '/manage_billing';
    }
  }

  secureAjax({ route, method, params, onSuccess, onFailure }) {
    onFailure = onFailure || console.log;
    params = params || {};
    method = method || 'get';
    // axios uses data for post request body, but params to form url of get request.
    const paramsName = method === 'post' ? 'data' : 'params';
    if (this.isAuthenticated()) {
      const url = process.env.REACT_APP_API_URL + route;
      return axios
        .request({
          url,
          method,
          withCredentials: true,
          [paramsName]: params,
        })
        .then((response) => onSuccess(response))
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.signout();
          } else if (error.response && error.response.status === 402) {
            this.sendToBilling();
          } else {
            onFailure(error);
            Sentry.captureException(new Error(error));
          }
        });
    }
  }
}

export default AuthService;
