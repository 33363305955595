import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import ShowSubmitted from './show_submitted';
import CreateAccountFlow from './create_account_flow';
import './signup.css';
import { Formik } from 'formik';
import { activateAccountValidationSchema } from '../../services/validation_schema';
import Form from 'react-bootstrap/Form';
import { activateAccount, resendActivationToken } from './sign-up-utils';
import { SubmitButton } from './submit-button';
import { Link } from 'react-router-dom';
import Notification, { notify } from 'react-notify-toast';
import { faTimesSquare } from '@fortawesome/pro-light-svg-icons/faTimesSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Logo } from '../../components/logo';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export const ValidateActivationToken = ({ userEmail }) => {
  const [loading, setLoading] = useState(false);
  const setLoadingValue = (value) => {
    setLoading(value);
  };
  function DisplayErrors({ error, field }) {
    return error ? (
      <div style={{ color: '#fa2a43', paddingBottom: '3px', textAlign: 'center', fontSize: 12 }}>
        {error}
        <br />{' '}
      </div>
    ) : null;
  }
  const handleSubmit = (values) => {
    console.log(values);
    activateAccount(values, setLoadingValue);
  };

  const resendToken = () => resendActivationToken(userEmail);

  return (
    <div className="screen">
      <Notification />
      <div className="main-container">
        <Logo paddingBottom={0} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 13,
          }}
        >
          {userEmail && <p className="regular-text">Activation code has been sent to</p>}
          {userEmail && (
            <p className="regular-text" style={{ textAlign: 'center' }}>
              <b>{userEmail}</b>
            </p>
          )}

          <p className="regular-text">Enter code below</p>
        </div>
        <Formik
          enableReinitialize
          validationSchema={activateAccountValidationSchema}
          initialValues={{ activationToken: '' }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <Form onSubmit={handleSubmit} className="regular-form">
              <Form.Group controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Activation Code"
                  className="floating-label-custom"
                >
                  <Form.Control
                    type="text"
                    value={values.activationToken}
                    onChange={handleChange}
                    className="form-input"
                    placeholder=" "
                    name="activationToken"
                    pattern="[0-9]*"
                  />
                </FloatingLabel>
              </Form.Group>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <SubmitButton loading={loading} title="Confirm" />
                <div
                  style={{ marginLeft: 20, backgroundColor: 'transparent' }}
                  className="primary-button transparent-variant"
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#fafafa', display: 'block' }}
                    to="/login"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        {userEmail && (
          <div style={{ marginTop: 15, color: '#fafafa' }}>
            <p onClick={() => resendToken()} style={{ cursor: 'pointer' }}>
              Didn't receive a code? <span className="verifyCodeText">Resend </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
