import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const DeleteContactConfirmation = ({ deleteEmergencyContactConfirmation, handleModal }) => {
  return (
    <>
      <Modal show={true} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Delete emergency contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>And disable notifications to this person?</Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteEmergencyContactConfirmation} variant="primary">
            Yes
          </Button>
          <Button onClick={handleModal} variant="primary">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteContactConfirmation;
