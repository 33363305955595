import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './landscape_blocker.css';

class LandscapeBlocker extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const showModal = this.props.showModal;
    return (
      <Modal
        show={showModal}
        size="xl"
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="modal-container">
          <div className="phone"></div>
          <div className="message">For a better experience, please rotate your device!</div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default LandscapeBlocker;
