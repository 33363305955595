import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

class BackButton extends Component {
  render() {
    return (
      <div
        style={this.props.style}
        onClick={this.props.onClickAction}
        className={this.props.buttonClass}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  }
}

export default BackButton;
