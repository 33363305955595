import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Spinner from '../../components/spinner/spinner';
import ForgotPassword from './forgot_password';
import LoginForm from './login_form';
import './login_page.css';
import Notification from 'react-notify-toast';
import queryString from 'query-string';
import { Logo } from '../../components/logo';

class Login extends Component {
  // Found this solution while googling the error we are getting in console regarding memory leak
  // I think in our case it doesn't make any difference, but in other cases better not to use this approach
  // see docs - https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      loading: false,
      forgotPasswordForm: false,
      formMessage: '',
    };
    this.url = window.location.href;
  }

  handleStateChange = (value) => {
    const name = value.name;
    if (this._isMounted) {
      this.setState({
        [name]: value.state,
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    const queryParams = queryString.parse(this.props.location.search);
    console.log(JSON.stringify(queryParams));
    const mobileAppValue = queryParams.mobile_app;
    if (mobileAppValue) {
      localStorage.mobile_app = 'true';
    } else {
      localStorage.mobile_app = 'false';
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { redirectToReferrer } = this.state;
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div className="screen">
        {this.state.loading && <Spinner message="Loading" />}
        {
          <div>
            {this.state.forgotPasswordForm ? (
              <ForgotPassword
                formMessage={this.state.formMessage}
                handleStateChange={this.handleStateChange}
              />
            ) : (
              <>
                <Notification />
                {!this.state.loading && (
                  <LoginForm
                    formMessage={this.state.formMessage}
                    handleStateChange={this.handleStateChange}
                    {...this.props}
                  />
                )}
              </>
            )}
          </div>
        }
      </div>
    );
  }
}

export default Login;

//random commit to test the build
