import React, { Component } from 'react';

// import { RadarSpinner } from 'react-epic-spinners'
import MDSpinner from 'react-md-spinner';
import './spinner.css';

class Spinner extends Component {
  render() {
    return (
      <div
        className="spinner-container"
        onTouchMove={(e) => {
          e.preventDefault();
        }}
        onDrag={(e) => {
          e.preventDefault();
        }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div className="spinner-and-text-holder">
          <div className="text-container" style={{ paddingBottom: '25px', lineHeight: '25px' }}>
            <span style={{ fontSize: '25px' }}>{this.props.message}</span> <br />
            {this.props.bike && <span style={{ fontSize: '23px' }}>{this.props.bike.vrn}</span>}
          </div>
          <MDSpinner size={100} singleColor={'#D7A157'} />
        </div>
      </div>
    );
  }
}

export default Spinner;
