import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import handleInputChange from '../services/handle_input_change';
import ToastNotifications from './toasty-notifications/toasty-notifications';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

class PasswordForm extends Component {
  constructor(props) {
    super(props);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    };
  }

  validatePassword() {
    return this.state.password === this.state.passwordConfirmation;
  }

  handlePasswordSubmit(event) {
    event.preventDefault();
    if (!this.validatePassword()) {
      ToastNotifications.errorToast('', "Whoops! Passwords don't match", 5000);
    } else {
      this.props.handleSubmit(
        this.state.currentPassword,
        this.state.password,
        this.state.passwordConfirmation,
      );
    }
  }

  render() {
    return (
      <Form className="default-form" onSubmit={(e) => this.handlePasswordSubmit(e)}>
        {!this.props.initialSetup && (
          <Form.Group controlId="formBasicPassword">
            <FloatingLabel
              controlId="floatingInput"
              label="Current Password"
              className="floating-label-custom"
            >
              <Form.Control
                type="password"
                name="currentPassword"
                value={this.state.currentPassword}
                className="form-input"
                onChange={this.handleInputChange}
                placeholder=" "
              />
            </FloatingLabel>
          </Form.Group>
        )}

        <Form.Group controlId="formBasicPassword">
          <FloatingLabel
            controlId="floatingInput"
            label="New Password"
            className="floating-label-custom"
          >
            <Form.Control
              type="password"
              name="password"
              value={this.state.password}
              className="form-input"
              onChange={this.handleInputChange}
              placeholder=" "
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <FloatingLabel
            controlId="floatingInput"
            label="Confirm Password"
            className="floating-label-custom"
          >
            <Form.Control
              type="password"
              value={this.state.passwordConfirmation}
              name={'passwordConfirmation'}
              className="form-input"
              onChange={this.handleInputChange}
              placeholder=" "
            />
          </FloatingLabel>
        </Form.Group>
        <Button
          className="primary-button"
          type="submit"
          disabled={this.state.password === '' || this.state.passwordConfirmation === ''}
        >
          Set password
        </Button>
      </Form>
    );
  }
}

export default PasswordForm;
