import React from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

const LogOutButton = withRouter(({ history, authService }) => (
  <button onClick={authService.signout} className="settings_page_buttons">
    <div className="settings-option-container">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src="/icons/logoutRound.png" alt="logout icon" className="settings_button_icon" />
        <p
          className="regular-text reset-margin reset-margin"
          style={{ fontSize: 25, paddingLeft: 0 }}
        >
          Log Out
        </p>
      </div>
      <FontAwesomeIcon
        color="#7C7A7A"
        style={{ fontSize: 17, textAlign: 'right', alignSelf: 'center' }}
        icon={faChevronRight}
      />
    </div>
  </button>
));

export default LogOutButton;
