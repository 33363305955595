import axios from 'axios';
import * as Sentry from '@sentry/react';
import ToastNotifications from '../../components/toasty-notifications/toasty-notifications';
import errorToast from '../../components/toasty-notifications/toasty-notifications';

export const submitNewCustomerDetails = (values, validateToken, setUserEmail, setLoadingValue) => {
  setLoadingValue(true);
  const route = process.env.REACT_APP_API_URL + '/sign_up';
  return axios
    .post(route, values, { withCredentials: true })
    .then((response) => {
      setLoadingValue(false);
      setUserEmail(values.email);
      validateToken(true);
    })
    .catch((error) => {
      setLoadingValue(false);
      if (error.response && error.response.data.error) {
        console.log(JSON.stringify(error.response.data));
        ToastNotifications.errorToast(error, error.response.data.error, -1);
      } else {
        Sentry.captureException(new Error(error));
        ToastNotifications.defaultErrorToast(error, -1);
      }
    });
};

export const activateAccount = (values, setLoadingValue) => {
  setLoadingValue(true);
  const route = process.env.REACT_APP_API_URL + '/activate_account';
  return axios
    .post(
      route,
      {
        email: values.email,
        confirmToken: values.activationToken,
        addedDevices: values.addedDevices,
      },
      { withCredentials: true },
    )
    .then((response) => {
      setLoadingValue(false);
      const timeout = 2000;
      ToastNotifications.successToast(
        'Email address has been confirmed! You will now be re-directed to continue with device activation',
        timeout,
      );
      setTimeout(() => {
        window.location = `activate_account/?confirm_token=${values.activationToken}`;
      }, timeout);
    })
    .catch((error) => {
      setLoadingValue(false);
      if (error.response && error.response.data.error) {
        console.log(JSON.stringify(error.response.data));
        ToastNotifications.errorToast(error, error.response.data.error, -1);
      } else {
        Sentry.captureException(new Error(error));
        ToastNotifications.defaultErrorToast(error, -1);
      }
    });
};
export const addDevices = (values, setLoadingValue) => {
  setLoadingValue(true);
  const route = process.env.REACT_APP_API_URL + '/add_devices';
  console.log(values);
  return axios
    .post(
      route,
      {
        confirmToken: values.activationToken,
        addedDevices: values.addedDevices,
      },
      { withCredentials: true },
    )
    .then((response) => {
      setLoadingValue(false);
      window.location.reload();
    })
    .catch((error) => {
      setLoadingValue(false);
      if (error.response && error.response.data.error) {
        console.log(JSON.stringify(error.response.data));
        ToastNotifications.errorToast(error, error.response.data.error, -1);
      } else {
        Sentry.captureException(new Error(error));
        ToastNotifications.defaultErrorToast(error, -1);
      }
    });
};

export const resendActivationToken = (email, redirect = false, link) => {
  const route = process.env.REACT_APP_API_URL + '/resend_activation_token';
  return axios
    .post(
      route,
      {
        email,
      },
      { withCredentials: true },
    )
    .then((response) => {
      if (redirect) {
        window.location = link;
      } else {
        ToastNotifications.successToast('Email has been sent!', 3000);
      }
      // window.location = '/login'
    })
    .catch((error) => {
      if (error.response && error.response.data.error) {
        console.log(JSON.stringify(error.response.data));
        ToastNotifications.errorToast(error, error.response.data.error, -1);
      } else {
        Sentry.captureException(new Error(error));
        ToastNotifications.defaultErrorToast(error, -1);
      }
    });
};

export const completeActivationProcess = (endLoading, authService) => {
  const onSuccess = () => {
    endLoading();
  };

  const onFailure = (error) => {
    endLoading();
    ToastNotifications.defaultErrorToast(error, -1);
    Sentry.captureException(new Error(error));
  };

  authService.secureAjax({
    route: '/complete_account_activation',
    method: 'post',
    onSuccess,
    onFailure,
  });
};
