import React, { useState } from 'react';
import './delete_account.css';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { SubmitButton } from '../../signup/submit-button';
import { deleteAccountValidation } from '../../../services/validation_schema';
import ToastNotifications from '../../../components/toasty-notifications/toasty-notifications';
import DeleteConfirmation from './delete_confirmation';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const DeleteAccount = ({ authService }) => {
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  // eslint-disable-next-line consistent-return
  const deleteUserAccount = ({ email, password, deleteWord }) => {
    const DELETE_ACCOUNT_WORD = 'DELETE';
    if (deleteWord !== DELETE_ACCOUNT_WORD) {
      ToastNotifications.errorToast('', 'Incorrect details were entered, OK', 5000);
      return null;
    }
    setPassword(password);
    setEmail(email);
    setShow(true);
  };

  return (
    <div
      style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 20, marginTop: 20 }}
    >
      {show && (
        <DeleteConfirmation
          authService={authService}
          password={password}
          email={email}
          setShow={setShow}
        />
      )}
      <p className="regular-text">
        Are you absolutely sure you want to delete your whole account? You will loose everything:
        all previous ride data, all tracking history — along with any access to your Motohalo
        devices. All subscriptions will be cancelled and everything will be gone forever.
      </p>
      <p className="regular-text">If you're 100% sure, confirm by logging in:</p>
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          password: '',
          deleteWord: '',
        }}
        onSubmit={deleteUserAccount}
        validationSchema={deleteAccountValidation}
      >
        {({ handleChange, values, handleSubmit, errors, isValid, touched, handleBlur }) => (
          <Form onSubmit={handleSubmit} className="default-form">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Enter Email"
                className="floating-label-custom"
              >
                <Form.Control
                  type="text"
                  name="email"
                  value={values.email}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Enter password"
                className="floating-label-custom"
              >
                <Form.Control
                  type="text"
                  name="password"
                  value={values.password}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="And typing the word DELETE below"
                className="floating-label-custom"
              >
                <Form.Control
                  type="text"
                  name="deleteWord"
                  value={values.deleteWord}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <SubmitButton title="Delete Everything" />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DeleteAccount;
