import React, { useEffect, useState } from 'react';
import Spinner from '../../components/spinner/spinner';
import Button from 'react-bootstrap/Button';

const ManageBilling = ({ authService }) => {
  const [loading, setLoading] = useState(true);
  const [stripeSessionUrl, setStripeSessionUrl] = useState(null);
  const [accountStatus, setAccountStatus] = useState(null);
  const [error, setError] = useState(null);
  const [billingMethod, setBillingMethod] = useState(null);

  useEffect(() => {
    authService.secureAjax({
      route: '/manage_billing',
      onSuccess(response) {
        console.log(response);
        setLoading(false);
        const { url, status, billingMethod } = response.data;
        setStripeSessionUrl(url);
        setAccountStatus(status);
        setBillingMethod(billingMethod);
      },
      onFailure(error) {
        console.error(error);
        setError(
          "Sorry, looks like we're having difficulty with your account.\n" +
            'Please contact our customer support team at support@bikeeyes.co.uk.',
        );
      },
    });
  }, []);

  if (loading && !error) {
    return <Spinner message="Loading" />;
  }

  const displayPaymentManagement = (billingMethod) => {
    if (billingMethod == 'automated') {
      return (
        <>
          <p className="regular-text" style={{ textAlign: 'left' }}>
            To edit your payment settings, click the button below to be redirected to Stripe, our
            trusted third-party payment provider who will manage your payment securely.
          </p>
          <Button
            onClick={() => {
              window.location = stripeSessionUrl;
            }}
            size="sm"
            className="primary-button"
            block
            style={{ marginTop: 20 }}
          >
            Manage Payments
          </Button>
        </>
      );
    } else {
      return (
        <p className="regular-text" style={{ textAlign: 'left' }}>
          Automated billing has not been set up for this account. Please contact our customer
          support team at accounts@motohalo.co.uk who will be able to assist you with any enquiries.
        </p>
      );
    }
  };

  return (
    <div style={{ paddingTop: 15 }} className="container">
      {displayAccountStatus(accountStatus)}
      {displayPaymentManagement(billingMethod)}
    </div>
  );
};

function displayAccountStatus(accountStatus) {
  if (accountStatus === 'payment_required') {
    return 'There appears to be an issue with your payment history.';
  }
}

export default ManageBilling;
