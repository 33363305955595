import React from 'react';
import BackButton from './back_button';

//Empty div we need for easy alignment
export const BackButtonHeader = ({ text, onPress }) => (
  <div className="back-button-header-container">
    <BackButton onClickAction={onPress} buttonClass="back" />
    <div className="back-button-header-title" style={{ fontWeight: 'bold' }}>
      {text}
    </div>
    <div></div>
  </div>
);
