import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      error: null,
      errorInfo: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    console.log('getDerivedStateFromError' + error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error + errorInfo);
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({
        eventId,
        error: error,
        errorInfo: errorInfo,
      });
      console.log(error + errorInfo);
    });
  }

  render() {
    if (this.state.error) {
      console.log(`in error boundary: ${this.state.error}`);

      //render fallback UI
      return (
        <div className="screen">
          <div className="main-container">
            <div style={{ fontSize: '85px' }}>🙄</div>
            <h3 style={{ color: 'white' }}>Oops... Sorry, something went wrong.</h3>
            <h3 style={{ color: 'white' }}> Please let us know!</h3>
            <button
              className="gold-button-variant"
              style={{ width: '50%', marginTop: '25px' }}
              onClick={() => {
                Sentry.showReportDialog({ eventId: '23735b893e9a4147a0fdf17c012fd27e' });
              }}
            >
              Report feedback
            </button>
          </div>
        </div>
      );
    }
    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
