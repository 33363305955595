import React, { useState } from 'react';
import './signup.css';
import { Formik } from 'formik';
import { signUpValidationSchema } from '../../services/validation_schema';
import Form from 'react-bootstrap/Form';
import { submitNewCustomerDetails } from './sign-up-utils';
import { SubmitButton } from './submit-button';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import Notification from 'react-notify-toast';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Logo } from '../../components/logo';

export const SignUpForm = ({ validateToken, setUserEmail }) => {
  const [loading, setLoading] = useState(false);
  const setLoadingValue = (value) => {
    setLoading(value);
  };
  function DisplayErrors({ error, field }) {
    return error ? (
      <div style={{ color: '#fa2a43', paddingBottom: '3px', fontSize: 12, textAlign: 'center' }}>
        {error}
        <br />{' '}
      </div>
    ) : null;
  }
  const handleSubmit = (values) => {
    submitNewCustomerDetails(values, validateToken, setUserEmail, setLoadingValue);
  };
  return (
    <div className="screen">
      <Notification />
      <div className="main-container">
        <Logo paddingBottom={15} />
        <h5>Get a Motohalo Account</h5>
        <Formik
          enableReinitialize
          validationSchema={signUpValidationSchema}
          initialValues={{ name: '', phoneNumber: '', email: '', password: '' }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <Form
              onSubmit={handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 20 }}
            >
              <Form.Group controlId="customerForm.name">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Full Name"
                  className="floating-label-custom"
                >
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    className="form-input"
                    onChange={handleChange}
                    placeholder=" "
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="customerForm.email">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="floating-label-custom"
                >
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    className="form-input"
                    onChange={handleChange}
                    placeholder=" "
                  />
                  <DisplayErrors error={touched.email && errors.email} field="email" />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="customerForm.phoneNumber">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone Number"
                  className="floating-label-custom"
                >
                  <Form.Control
                    type="text"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    className="form-input"
                    onChange={handleChange}
                    placeholder=" "
                  />
                  <DisplayErrors
                    error={touched.phoneNumber && errors.phoneNumber}
                    field="phoneNumber"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="customerForm.password">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Password"
                  className="floating-label-custom"
                >
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    className="form-input"
                    onChange={handleChange}
                    placeholder=" "
                  />
                  <DisplayErrors error={touched.password && errors.password} field="password" />
                </FloatingLabel>
              </Form.Group>
              <SubmitButton loading={loading} title="Submit" />
            </Form>
          )}
        </Formik>

        <p
          onClick={() => validateToken(true)}
          style={{ cursor: 'pointer', color: '#fafafa', margin: 0 }}
          className="clickable-link"
        >
          Verify Activation Code
        </p>
        <Link style={{ textDecoration: 'none' }} to="/login">
          <p
            className="clickable-link"
            style={{
              color: '#fafafa',
              margin: 0,
            }}
          >
            Sign In
          </p>
        </Link>
      </div>
    </div>
  );
};
