import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { completeActivationProcess } from '../signup/sign-up-utils';
import Spinner from '../../components/spinner/spinner';
import './smart-banners.css';
import '../signup/signup.css';
import { isAndroid, isMobile } from 'react-device-detect';
import SimpleLogOutButton from './simple_logout_button';
import SmartBanner from 'react-smartbanner';

export const STORE_TEXT = 'Keep eyes on your Motorcycle';

export const SuccessScreen = ({ authService }) => {
  // const [submitDevices, setSubmitDevices] = useState(false);
  const [loading, setLoading] = useState(false);
  const mobileApp = localStorage.mobile_app;

  const endLoading = () => setLoading(false);

  // useEffect(() => {
  //   completeActivationProcess(endLoading, authService);
  // }, []);

  const getIOSApp = () => {
    window.location = 'https://apps.apple.com/gb/app/moto-halo/id1505515077';
  };

  const getAndroidApp = () => {
    window.location = 'https://play.google.com/store/apps/details?id=com.bikeeyes';
  };

  return (
    <div style={{ gap: 20, display: 'flex', flexDirection: 'column' }}>
      {loading && <Spinner message="Activating your account" />}
      <header className="success-screen-heading">
        <h4>Congratulations! Your Motohalo account is now activated 🥳</h4>
      </header>
      {/*{!loading && <SmartBanner title={'MotoHalo'} daysHidden={1} daysReminder={1} price={{ios: '', android: ''}} storeText={{ios: STORE_TEXT, android: STORE_TEXT}}/> }*/}

      <p className="regular-text">
        Tracking device (s) were transferred to your account ready for installation. To complete the
        setup process please install the tracking device and download our mobile app to access your
        new account.
      </p>
      {!isMobile ? (
        <div className="regular-form">
          <Button className="primary-button" onClick={() => getIOSApp()}>
            Get iPhone App 📱
          </Button>
          <Button className="primary-button" onClick={() => getAndroidApp()}>
            Get Android App 📱
          </Button>
        </div>
      ) : isAndroid ? (
        <Button className="primary-button" onClick={() => getAndroidApp()}>
          GET MOTOHALO APP 📱
        </Button>
      ) : (
        <Button className="primary-button" onClick={() => getIOSApp()}>
          GET MOTOHALO APP 📱
        </Button>
      )}
    </div>
  );
};
