import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const SimpleLogOutButton = withRouter(({ history, authService, title }) => (
  <Button
    onClick={authService.signout}
    type="submit"
    style={{ fontWeight: 600 }}
    className={'black-button-variant'}
  >
    {title}
  </Button>
));

export default SimpleLogOutButton;
