import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import * as Sentry from '@sentry/react';

class NetworkDetector extends Component {
  state = {
    isDisconnected: false,
  };

  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing);
            });
          })
          .catch((error) => {
            console.log(error);
            Sentry.captureException(new Error(error));
          });
      }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  };

  refreshPage = () => {
    window.location.reload();
  };

  render() {
    const { isDisconnected } = this.state;
    return (
      <div>
        {isDisconnected && (
          <div className="internet-error">
            <p>Connection Lost. Trying to reconnect...</p>
            <div onClick={this.refreshPage}>
              <FontAwesomeIcon icon={faSync} spin />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NetworkDetector;
