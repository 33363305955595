import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToastNotifications from '../../../../components/toasty-notifications/toasty-notifications';
import { emailValidationSchema } from '../../../../services/validation_schema';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const UpdateEmailRequest = ({ userEmail, authService, setCustomerData }) => {
  const updateUserEmail = (inputData) => {
    const { email, currentPassword } = inputData;
    authService.secureAjax({
      params: { email, currentPassword },
      route: '/process_email_update',
      method: 'post',
      onSuccess() {
        setCustomerData();
      },
      onFailure(error) {
        let errorMsg = 'Error occurred while sending data';
        console.log(error.response.status);
        if (error.response.data.errors.length > 0) {
          errorMsg = error.response.data.errors[0];
        }
        ToastNotifications.errorToast('', errorMsg, 3000);
      },
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          email: userEmail,
          currentPassword: '',
        }}
        onSubmit={updateUserEmail}
        validationSchema={emailValidationSchema}
      >
        {({ handleChange, handleBlur, values, handleSubmit, errors, isValid }) => (
          <Form
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', gap: 20 }}
          >
            <Form.Group style={{ display: 'flex', flex: 1 }}>
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="floating-label-custom"
              >
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="customerForm.password" style={{ display: 'flex', flex: 1 }}>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className="floating-label-custom"
              >
                <Form.Control
                  type="password"
                  name="currentPassword"
                  value={values.currentPassword}
                  className="form-input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <p className="regular-text">
              Your current password is required to update or change the account email address
            </p>
            <Button className="primary-button" type="submit" disabled={!isValid}>
              Update Details
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateEmailRequest;
