import React from 'react';
import Image from 'react-bootstrap/Image';

export const Logo = ({ paddingBottom = 35, height = 60 }) => (
  <div className="logo-main">
    <Image
      src={'/logo_transparent.png'}
      style={{ height: height, objectFit: 'contain', paddingBottom }}
      className="mx-auto"
    />
  </div>
);
