import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import handleInputChange from '../../services/handle_input_change';
import ToastNotifications from '../../components/toasty-notifications/toasty-notifications';
import { Link } from 'react-router-dom';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Logo } from '../../components/logo';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      email: '',
      password: '',
      rememberMe: true,
      disabledButton: false,
    };
    this.login = this.login.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
  }

  handleSubmit(event) {
    if (!this.state.email || !this.state.password) {
      event.preventDefault();
      this.setState({ disabledButton: true });
      this.props.handleStateChange({
        name: 'formMessage',
        state: 'Email or Password invalid',
      });
    } else {
      event.preventDefault();
      this.login();
    }
  }

  login() {
    this.props.handleStateChange({
      name: 'loading',
      state: true,
    });
    const { email, password, rememberMe } = this.state;
    this.props.authService
      .authenticate(email, password, rememberMe)
      .then(({ isAuthenticated, data, error }) => {
        if (isAuthenticated) {
          this.props.handleDataOnLogin(data);

          this.props.handleStateChange({
            name: 'redirectToReferrer',
            state: true,
          });
        } else if (error.response && error.response.status === 401) {
          this.props.handleStateChange({
            name: 'formMessage',
            state: 'Email or Password invalid',
          });
        } else if (error.response && error.response.status === 409) {
          ToastNotifications.unverifiedEmailToast(error.response.data.error, -1, email);
        } else {
          this.props.handleStateChange({
            name: 'formMessage',
            state: 'Ooops! Something went wrong...',
          });
        }
        this.props.handleStateChange({
          name: 'loading',
          state: false,
        });
      });
  }

  render() {
    return (
      <>
        <div className="main-container">
          <Logo paddingBottom={15} />
          <Form
            onSubmit={(e) => this.handleSubmit(e)}
            style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', gap: 25 }}
          >
            {this.props.formMessage && (
              <div style={{ color: 'red', fontSize: '16px' }}>{this.props.formMessage}</div>
            )}
            <Form.Group controlId="formBasicEmail">
              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="floating-label-custom"
              >
                <Form.Control
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className="floating-label-custom"
              >
                <Form.Control
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>
            <Button
              style={{ marginTop: 15 }}
              className="primary-button"
              type="submit"
              disabled={this.state.email && this.state.password ? false : this.state.disabledButton}
            >
              Sign In
            </Button>
          </Form>
          <Link style={{ textDecoration: 'none' }} to="/signup" className="clickable-link">
            Sign Up
          </Link>
          <Link
            className="clickable-link"
            onClick={() =>
              this.props.handleStateChange({
                name: 'forgotPasswordForm',
                state: true,
              })
            }
          >
            Reset password
          </Link>
        </div>
      </>
    );
  }
}

export default LoginForm;
